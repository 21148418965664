<template>
    <div>
      <h2>{{ $t('pages.competition-admin.list.title') }}</h2>
      <p>{{ $t('pages.competition-admin.list.intro') }} <info-helper><div v-html="$t('pages.competition-admin.list.intro-info-helper')"></div></info-helper></p>
      <server-not-responding>      
        <local-data-only-info-panel></local-data-only-info-panel>
      </server-not-responding>

      <div v-if="competitions == null || competitions.length == 0">{{ $t('pages.competition-admin.list.no-found') }}</div>
      <competitions-list
          :items="competitions"
          :canDelete="userCanDelete"
          :confirmBeforeDeleting="true"
          :canLock="userHasRole(Roles.Admin)"
          :canUnlock="userHasRole(Roles.Admin)"
          @select="selectCompetition"
          @remove="deleteCompetition"
          @lock-change="changeLockState"
      >
      </competitions-list>     

    </div>
</template>

<script>
import SecurityMap from "@/Constants/securityMap.js";
import CompetitionsList from '@/components/CompetitionsList'
import CompetitionsConfigurationHelper from '@/services/helpers/competitionsConfigurationHelper';
import InfoHelper from "../../components/InfoHelper.vue";
import ServerNotResponding from "../../components/Panels/ServerNotResponding.vue";
import LocalDataOnlyInfoPanel from '../../components/Panels/Info/LocalDataOnly.vue';

export default {
    components: { CompetitionsList, InfoHelper, ServerNotResponding, LocalDataOnlyInfoPanel },
    data(){
      return {
        competitions: [],
        userCanDelete: false,
      };
    },
    computed:{
        securityMapOperations(){ return SecurityMap.Operations; },
    },
    methods:{
        async refresh(){
          this.userCanDelete = this.userCanNew(this.securityMapOperations.deleteCompetition);
          this.competitions = await CompetitionsConfigurationHelper.getUserRunningCompetitionsAsync(this.isTestMode);
        },
        async selectCompetition(competition)
        {
          await this.showLoader();
          this.setCurrentCompetition_id(competition.id);
          var elementsToCorrect = CompetitionsConfigurationHelper.missingRequirementsBeforeRun(competition.id);
          if(elementsToCorrect.length > 0){
              this.$router.push({ name: 'CompetitionEdit', params: { id: competition.id }});
          } else {
              this.$router.push({ name: 'CompetitionEditOrRun', params: { id: competition.id }} );
          }
        },
        async deleteCompetition(competition)
        {
          await this.showSaver();
          try{
            var result = CompetitionsConfigurationHelper.deleteCompetition(competition.id);
            this.toastAsyncSaved(result, 'Compétition supprimée');
          }catch(error)
          {
            console.log('deleteCometition Error', error);
            this.toastSaveFailure(error);
          }
          this.$hideSaver();
        },
        async changeLockState(competition, state){
          console.log('changeLockState', { competition: competition, state: state});
          await this.showSaver();
          try{
            var result = await CompetitionsConfigurationHelper.changeCompetitionLockStateAsync(competition.id, state);
            this.toastAsyncSaved(result, state ? "Compétition vérouillée" : "Compétition déverrouillée");
            await this.refresh();
          } catch(error){
            console.log('changeLockState error', error);
            this.toastSaveFailure(error);
          }
          this.$hideSaver();
        }
    },
    async mounted(){
      await this.showLoader();
      await this.refresh();
      await this.checkSynchro();
      this.$hideLoader();
    }
}
</script>
